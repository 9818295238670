/**
 * Bonus challenge page
 * 
 * Shows a card that displays the bonus challenge title and text.
 * Used in cases where there's an extra challenge the hosts provide,
 * or sometimes for just extra text context in other types of games.
 */
import React, { useContext, useEffect, useState } from 'react'

import LoggedInWrapper from '../components/loggedinWrapper'
import SvgArrow from '../components/svg/arrow'
import Link from '../components/link'
import AppContext from '../utils/context'
import { getCurrentHuntData } from '../utils/hunt-data'

const BonusChallengePage = () => {
  const context = useContext(AppContext)
  const [data, setData] = useState(getCurrentHuntData())

  useEffect(() => {
    setData(getCurrentHuntData())
    context.setLoading(false)
  }, [])

  return (
    <LoggedInWrapper>
      {data.hunt !== undefined && (
        <>
          <div className="site-sub-header px-4 py-2">
            <div className="row">
              <div className="col-2 d-flex align-items-center">
                <Link to={`/`} className={`back-arrow`}>
                  <SvgArrow />
                </Link>
              </div>
              <div className="col-10 text-right">
                <h1 className={`title h5 smaller text-uppercase mb-0 d-inline`}>
                  {data.hunt.bonus_challenge_title
                    ? data.hunt.bonus_challenge_title
                    : `Bonus Challenge`}
                </h1>
              </div>
            </div>
          </div>
          <div className="mt-3 mb-3">
            <div className={`card mx-4`}>
              <div className="card-header text-center">
                <h3>
                  {data.hunt.bonus_challenge_card_heading
                    ? data.hunt.bonus_challenge_card_heading
                    : `For Extra Points...`}
                </h3>
              </div>
              <div className="card-body pb-3">
                <div className="card-text" dangerouslySetInnerHTML={{ __html: data.hunt.bonus_challenge_text }} />
              </div>
            </div>
          </div>
        </>
      )}
    </LoggedInWrapper>
  )
}

export default BonusChallengePage
